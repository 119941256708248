import { memo } from 'react';
import { css } from '@emotion/react';
import useMedia from '~/hooks/useMedia';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import SocketTable from '~/modules/options/sharedComponents/new';
import ContractsSelect from '~/modules/options/sharedComponents/ContractsSelect';
import UnrealizeVirtualPlChart from '~/modules/virtual-exchange-center/components/Wrapper/UnrealizeVirtualPlChart';
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore';
import { SignalrTopic } from '~/modules/SDK/Signalr/SignalrTopic';
export const OpbsWrapper = memo(function OpbsWrapper() {
    const { isPc } = useMedia();
    return (<div css={css `
        margin-top: 8px;
      `}>
      <ContractsSelect disabled={false} onChanged={month => {
            useSignalrStore.getState().subscribeAddTopic([`${SignalrTopic.tw_options}:${month}`]);
            useSignalrStore
                .getState()
                .subscribeAddTradeInfoTopic([`${SignalrTopic.tw_options}:${month}`]);
        }} onDemount={month => {
            useSignalrStore.getState().subscribeRemoveTopic([`${SignalrTopic.tw_options}:${month}`]);
            useSignalrStore
                .getState()
                .subscribeRemoveTradeInfoTopic([`${SignalrTopic.tw_options}:${month}`]);
        }}/>
      <div css={css `
          margin: 8px;
          ${isPc ? flex.h.default : flex.v.default};
        `}>
        <div css={css `
            width: 960px;
            height: 500px;
          `}>
          <SocketTable />
        </div>
        <div css={css `
            width: ${isPc ? '500px' : '500px'};
            height: ${isPc ? '500px' : '500px'};
          `}>
          <UnrealizeVirtualPlChart />
        </div>
      </div>
    </div>);
});
